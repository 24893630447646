/** @format */

import React, { useState } from "react";
import { useWeb3Modal } from "@web3modal/wagmi/react";
import { useAccount, useEnsName, useSwitchChain } from "wagmi";
import axios from "axios";
import Web3 from "web3";
import useRequest from "@ahooksjs/use-request";
import toast from "react-hot-toast";
import { IN_GAME_ABI, IN_GAME_ADDRESS } from "../../abi/inGame";

const Claim = () => {
  var web3 = new Web3(Web3.givenProvider || "http://localhost:3002/");
  const { open, close } = useWeb3Modal();
  const { address, isConnected, isConnecting, chain } = useAccount();
  const { data: ensName } = useEnsName({ address });
  const { switchChain } = useSwitchChain();
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [allClaim, setAllClaim] = useState([]);

  const toWei = (value, unit = "ether") => {
    return web3.utils.toWei(value, unit);
  };
  const fromWei = (value, unit = "ether") => {
    return web3.utils.fromWei(value.toString(), unit);
  };
  function truncate(str, n) {
    return str.length > n ? str.slice(0, 6) + "..." + str.slice(-6) : str;
  }

  const getGameContract = async () => {
    return web3
      ? new web3.eth.Contract(IN_GAME_ABI, IN_GAME_ADDRESS, {
          from: web3.eth.defaultAccount,
        })
      : null;
  };

  const handleClaim = async data => {
    // console.log("data  ===>", data);
    setIsLoading(true);
    const rpcGasPrice = await web3.eth.getGasPrice();
    const contract = await getGameContract();
    contract?.methods
      .claimReward(data?.claimId, toWei(data?.reward), data?.signature)
      .send({
        from: address,
        gasPrice: rpcGasPrice,
      })
      .then(value => {
        console.log(value);
        setIsLoading(false);
        toast.success("Operation Successful");
      })
      .catch(error => {
        console.log(error);
        toast.error(error?.message);
        setIsLoading(false);
      });
  };

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        "https://players-mpepe.onrender.com/api/v1/claimReward/get",
      );
      setAllClaim(response?.data.claimIds);
      //   console.log(allClaim);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching users:", error);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };
  useRequest(fetchData, {
    loadingDelay: 500,
    pollingInterval: 10000,
    pollingWhenHidden: false,
    refreshOnWindowFocus: true,
    throttleInterval: 1000,
    throwOnError: true,
  });

  return (
    <>
      <div
        className='min-h-screen flex-1  p-4 flex justify-center items-center'
        id='claim'>
        <div
          className='rounded-md bg-[#4caf4f5d] p-8 container max-w-7xl mx-auto border-dashed border-2 border-[#DDF1B5]'
          data-aos='zoom-in'>
          <div className='flex flex-col gap-6 rounded-md bg-[#4caf4f83] p-4'>
            <div className=' rounded-xl bg-[#4caf4fce] '>
              <div className='h-12 flex justify-between items-center  m-4'>
                <div>
                  <div className='text-xl font-semibold text-[#DDF1B5] font-head'>
                    Claim your Reward
                  </div>
                  <div className='text-sm font-base text-white font-para'>
                    Waiting for more reward...
                  </div>
                </div>
              </div>
              {isConnected ? (
                <div className='px-6'>
                  {allClaim.length !== 0
                    ? allClaim
                        .filter(add => add.address === address)
                        .map(data => {
                          return (
                            <div
                              className='flex justify-between items-center h-16 p-4 my-6  rounded-lg  shadow-md bg-[#1F4921]'
                              key={data.id}>
                              <div className='flex items-center'>
                                <img
                                  className='rounded-full h-12 w-12'
                                  src='assets/mpepe.png'
                                  alt='Logo'
                                />
                                <div className='ml-2'>
                                  <div
                                    className='text-sm font-semibold text-[#DDF1B5] font-para cursor-pointer'
                                    onClick={() => open()}>
                                    {isConnected
                                      ? truncate(
                                          `${
                                            ensName
                                              ? `${ensName} (${address})`
                                              : address
                                          }`,
                                          8,
                                        )
                                      : "---"}
                                  </div>
                                </div>
                              </div>
                              <div className='text-base font-semibold text-white font-para'>
                                <strong>{data?.reward}</strong> {/*    */}$MPepe
                              </div>
                              <div>
                                <button
                                  onClick={() => handleClaim(data)}
                                  disabled={data?.signature ? false : true}
                                  className='bg-[#DDF1B5] hover:opacity-70 hover:animate-bounce px-3 py-2 rounded-sm font-para font-semibold shadow-md flex justify-center items-center'>
                                  {isLoading
                                    ? "Confirming..."
                                    : data?.signature
                                    ? "Claim Reward"
                                    : "In-Progress"}
                                </button>
                              </div>
                            </div>
                          );
                        })
                    : "No Claim Record"}
                </div>
              ) : (
                <button
                  onClick={() => open()}
                  className='bg-[#DDF1B5] hover:opacity-70 hover:animate-bounce px-3 py-2 m-auto rounded-sm font-para font-semibold shadow-md flex justify-center items-center'>
                  Connect Account
                </button>
              )}
              <div className='p-6 flex justify-center'>
                <a
                  href='https://play.minerpepe.com/'
                  target='_blank'
                  rel='noreferrer'
                  className='p-4 bg-green-400 hover:bg-green-500 w-full rounded-lg shadow text-xl text-center font-medium capitalize font-para text-white max-w-lg m-auto'>
                  Start the game
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Claim;
