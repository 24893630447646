/** @format */

import React, { useState } from "react";
import { Link } from "react-router-dom";
const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleClick = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  return (
    <header className=''>
      <div className='flex flex-wrap items-center px-10 py-4 relative  min-h-[60px]   z-30'>
        <a href='#!' className='hidden max-lg:block'>
          <img src='assets/logo.png' alt='logo' className='w-36' />
        </a>
        <div className='flex ml-auto lg:order-1 lg:hidden'>
          <button
            id='toggle'
            onClick={handleClick}
            className='ml-7 z-40 relative'>
            <svg
              className='w-7 h-7'
              fill='#fff'
              viewBox='0 0 20 20'
              xmlns='http://www.w3.org/2000/svg'>
              <path
                fillRule='evenodd'
                d='M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z'
                clipRule='evenodd'
              />
            </svg>
          </button>
        </div>
        <div
          id='collapseMenu'
          style={{ display: isMenuOpen ? "block" : "none" }}
          className='lg:!flex lg:items-center w-full max-lg:hidden max-lg:py-4'>
          <ul className='lg:flex lg:space-x-8 max-lg:space-y-2'>
            <li className=' max-lg:py-2'>
              <Link
                to='/'
                className='hover:text-[#fff] font-semibold text-[#fff] block text-[15px] font-para'>
                Home
              </Link>
            </li>
            <li className=' max-lg:py-2'>
              <a
                href='https://miner-pepe-official.gitbook.io/mpepe/'
                target='_blank'
                rel='noreferrer'
                className='hover:text-[#fff] font-semibold text-[#DDF1B5] block text-[15px] font-para'>
                PepePaper
              </a>
            </li>
            <li className=' max-lg:py-2'>
              <a
                href='https://www.cyberscope.io/audits/2-mpepe'
                target='_blank'
                rel='noreferrer'
                className='hover:text-[#fff] font-semibold text-[#DDF1B5] block text-[15px] font-para'>
                Audit
              </a>
            </li>
            <li className=' max-lg:py-2'>
              <a
                href='https://play.minerpepe.com/'
                target='_blank'
                rel='noreferrer'
                className='hover:text-[#fff] font-semibold text-[#DDF1B5] block text-[15px] font-para'>
                Play Game
              </a>
            </li>
            <li className='text-[15px] font-para  max-lg:py-2'>
              <a
                 href='https://pancakeswap.finance/swap?outputCurrency=0x6d306C2C9CD931160763D99376a68C14D33DC954'
                title='PancakeSwap'
                className='hover:text-[#fff] font-semibold text-[#DDF1B5] block text-[15px] font-para'>
                Buy MPepe
              </a>
            </li>
          </ul>
          <a
            href='#!'
            className='m-auto absolute lg:left-2/4 lg:-translate-x-1/2 mt-20 max-lg:hidden'>
            <img src='assets/logo.png' alt='logo' className='w-36' />
          </a>
          <ul className='lg:flex lg:space-x-8 max-lg:space-y-2 ml-auto'>
            <li className=' max-lg:py-2'>
              <a
                href='#mining'
                className='hover:text-[#fff] font-semibold text-[#DDF1B5] block text-[15px] font-para'>
                Mining{" "}
              </a>
            </li>
            <li className=' max-lg:py-2'>
              <a
                href='#referral'
                className='hover:text-[#fff] font-semibold text-[#DDF1B5] block text-[15px] font-para'>
                Referral
              </a>
            </li>
            <li className=' max-lg:py-2'>
              <a
                href='#how-to-start'
                className='hover:text-[#fff] font-semibold text-[#DDF1B5] block text-[15px] font-para'>
                How to Start
              </a>
            </li>
            {/* <li className='text-[15px] font-para  max-lg:py-2'>
              <a
                href='#faqs'
                className='hover:text-[#fff] font-semibold text-[#DDF1B5] block text-[15px] font-para'>
                Faqs
              </a>
            </li> */}
          </ul>
        </div>
      </div>
    </header>
  );
};

export default Header;
