/** @format */

import React from "react";
import "./App.scss";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import NotFound from "./pages/NotFound";
import Dapp from "./pages/Dapp";
import { Toaster } from "react-hot-toast";
import AOS from "aos";
import "aos/dist/aos.css"; // You can also use <link> for styles
import PlayGame from "./pages/PlayGame";
const App = () => {
  React.useEffect(() => {
    AOS.init({ duration: 1500 });
  });

  return (
    <BrowserRouter>
      <Routes>
        <Route index path='/' element={<Home />} />
        <Route path='/dapp' element={<Dapp />} />
        <Route path='/play' element={<PlayGame />} />
        <Route path='/*' element={<NotFound />} />
      </Routes>
      <Toaster reverseOrder={false} position='bottom-center' />
    </BrowserRouter>
  );
};

export default App;
