/** @format */

import React, { useEffect, useState } from "react";
import { useWeb3Modal } from "@web3modal/wagmi/react";
import {
  useAccount,
  useEnsName,
  useReadContracts,
  useSwitchChain,
} from "wagmi";
import { useLocation } from "react-router-dom";
import { bsc } from "wagmi/chains";
import { formatEther } from "ethers";
import Web3 from "web3";
import CountUp from "react-countup";
import toast from "react-hot-toast";
import useAllowance from "../../abi/hooks/useAllowance";
import useBalance from "../../abi/hooks/useBalance";
import { ERC_ABI, MPEPE_ADDRESS } from "../../abi/erc20";
import { MPEPE_POOL_ABI, MPEPE_POOL_ADDRESS } from "../../abi/mpepe-minner";

const MpepePool = () => {
  var web3 = new Web3(Web3.givenProvider || "http://localhost:3002/");
  const { open } = useWeb3Modal();
  const { address, isConnected, chain } = useAccount();
  const { data: ensName } = useEnsName({ address });
  const { switchChain } = useSwitchChain();
  const [amount, setAmount] = useState("");
  const [inputError, setinputError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [balance] = useBalance(MPEPE_ADDRESS, 18);
  let allowanceWeb3 = useAllowance(MPEPE_ADDRESS, MPEPE_POOL_ADDRESS);
  const [allowance, setAllowance] = useState("0");
  const MAX_UINT =
    "0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff";
  useEffect(() => {
    setAllowance(allowanceWeb3);
  }, [allowanceWeb3]);

  const getERC20Contract = async () => {
    return web3
      ? new web3.eth.Contract(ERC_ABI, MPEPE_ADDRESS, {
          from: web3.eth.defaultAccount,
        })
      : null;
  };

  async function approve() {
    const rpcGasPrice = await web3.eth.getGasPrice();

    const contract = await getERC20Contract();
    contract?.methods
      .approve(MPEPE_POOL_ADDRESS, MAX_UINT)
      .send({ from: address, gasPrice: rpcGasPrice })
      .then(value => {
        toast.success("Transaction Confirmed");

        setAllowance(MAX_UINT);
        // resolve(new BN(value))
      })
      .catch(error => {
        console.log(error);
        toast.error(error?.message);
      });
  }

  function useQuery() {
    return new URLSearchParams(useLocation()?.search);
  }

  function truncate(str, n) {
    return str.length > n ? str.slice(0, 6) + "..." + str.slice(-6) : str;
  }
  const query = useQuery();
  const getRef = () => {
    const ref = web3.utils.isAddress(query.get("ref"))
      ? query.get("ref")
      : "0xE66aC68FFFAD24ca18C1cD35D8eF909098B662D6";
    return ref;
  };
  const ref = getRef();

  // console.log("ref", ref);

  function calcPerc(amount) {
    setAmount((balance / 100) * amount);
  }

  const contractValue = useReadContracts({
    contracts: [
      {
        address: MPEPE_POOL_ADDRESS,
        abi: MPEPE_POOL_ABI,
        functionName: "getBalance",
        chainId: bsc?.id,
        // args: [69]
      },
      // ...
    ],
  });

  const { data } = useReadContracts({
    contracts: [
      {
        address: MPEPE_POOL_ADDRESS,
        abi: MPEPE_POOL_ABI,
        functionName: "beanRewards",
        args: [address],
        chainId: bsc?.id,
      },
      {
        address: MPEPE_POOL_ADDRESS,
        abi: MPEPE_POOL_ABI,
        functionName: "getMyMiners",
        args: [address],
        chainId: bsc?.id,
      },
    ],
  });
  const [reward, myMiner] = data || [];
  const toWei = (value, unit = "ether") => {
    return web3.utils.toWei(value, unit);
  };
  const fromWei = (value, unit = "ether") => {
    return web3.utils.fromWei(value.toString(), unit);
  };
  // console.log("wei value: ", parseUnits(amount.toString(), 18));

  const getBeanContract = async () => {
    return web3
      ? new web3.eth.Contract(MPEPE_POOL_ABI, MPEPE_POOL_ADDRESS, {
          from: web3.eth.defaultAccount,
        })
      : null;
  };
  const handleStake = async () => {
    if (!amount.trim()) {
      setinputError(true);
      return;
    }
    setinputError(false);
    setIsLoading(true);
    const rpcGasPrice = await web3.eth.getGasPrice();
    const contract = await getBeanContract();
    contract?.methods
      .ovenPepe(ref, toWei(amount))
      .send({
        from: address,
        gasPrice: rpcGasPrice,
      })
      .then(value => {
        console.log(value);
        setIsLoading(false);

        toast.success("Transaction Confirmed");
      })
      .catch(error => {
        console.log(error);
        setIsLoading(false);

        toast.error(error?.message);
      });
  };
  // console.log("error", error?.message);

  const handleReStake = async () => {
    const rpcGasPrice = await web3.eth.getGasPrice();

    const contract = await getBeanContract();
    contract?.methods
      .heatPepe(ref)
      .send({
        from: address,
        gasPrice: rpcGasPrice,
      })
      .then(value => {
        console.log(value);
        toast.success("Transaction Confirmed");
      })
      .catch(error => {
        console.log(error);
        toast.error(error?.message);
      });
  };

  const handleUnStake = async () => {
    const rpcGasPrice = await web3.eth.getGasPrice();

    const contract = await getBeanContract();
    contract?.methods
      .sellPepes()
      .send({
        from: address,
        gasPrice: rpcGasPrice,
      })
      .then(value => {
        console.log(value);
        toast.success("Transaction Confirmed");
      })
      .catch(error => {
        console.log(error);
        toast.error(error?.message);
      });
  };

  const isPoolLive = false;
  return (
    // <section
    //   className='flex  flex-col justify-center gap-10 py-20 mx-auto w-full max-w-6xl px-4 sm:px-6 md:px-8 lg:px-10 xl:px-12 relative'
    //   id='mining'>
    //   <h2 className='text-center text-5xl text-[#DDF1B5] font-bold font-head tracking-wide '>
    //     Mining
    //   </h2>
    <div
      className='flex flex-col gap-12 rounded-md bg-[#4caf4f5d] p-4 lg:flex-row border-dashed border-2 border-[#DDF1B5] font-para text-white'
      data-aos='zoom-in'>
      <div className='flex w-full flex-col gap-2 lg:w-3/5 rounded-md bg-[#4caf4f83] p-4'>
        <div className='flex items-center'>
          <h3 className='flex-grow text-lg lg:text-xl font-head'>Miners</h3>
          <span className='ml-2 text-xl font-bold text-[#DDF1B5] lg:text-xl'>
            {myMiner?.status === "success" ? myMiner?.result?.toString() : "0"}
          </span>
        </div>
        {/* <div className='flex items-center'>
            <h3 className='flex-grow text-lg lg:text-xl font-head'>
              Mining Speed
            </h3>
            <span className='ml-2 text-xl font-bold text-[#DDF1B5] lg:text-2xl'>
              0.0000
            </span>
            <span className='ml-2 text-xl font-bold lg:text-xl'>BNB/24h</span>
          </div> */}
        <div className='flex items-center'>
          <h3 className='flex-grow text-lg lg:text-xl font-head'>My rewards</h3>
          <span className='ml-2 text-xl font-bold text-[#DDF1B5] lg:text-2xl'>
            {reward?.status === "success" ? (
              <CountUp
                delay={2}
                start={0}
                decimals={10}
                end={formatEther(reward?.result)}
              />
            ) : (
              // Number(formatEther(reward?.result.toString()))
              // formatEther(reward?.result)
              "0.00"
            )}
          </span>
          <span className='ml-2 text-xl font-bold lg:text-xl'>MPepe</span>
        </div>
        <div className='flex flex-col lg:px-4'>
          <div className='mt-8 flex items-center justify-between'>
            <hr className='hidden w-1/3 opacity-40 lg:inline-block' />
          </div>
          {/* <div className='mt-8 flex items-center justify-between'>
            <p className='text-lg lg:text-xl'>
              MIN: <span className='font-bold'>0.01</span>
            </p>
            <hr className='hidden w-1/3 opacity-40 lg:inline-block' />
            <p className='text-lg lg:text-xl'>
              MAX: <span className='font-bold'>200 </span>
            </p>
          </div> */}
          <div className='mt-5 flex items-center justify-between rounded-lg text-white font-para border-dashed border border-[#DDF1B5] bg-[#2e6e31] px-4 py-3'>
            <input
              className='w-full bg-transparent text-lg font-bold focus:outline-none'
              type='number'
              placeholder='0.01'
              onChange={e => setAmount(e.target.value)}
            />
            <div className='flex gap-4'>
              <svg
                onClick={() => calcPerc(100)}
                xmlns='http://www.w3.org/2000/svg'
                width={16}
                height={16}
                fill='currentColor'
                className='bi bi-patch-plus-fill h-7 w-7 transform cursor-pointer opacity-60 transition-transform duration-300 hover:scale-110 hover:text-white'
                viewBox='0 0 16 16'>
                <path d='M10.067.87a2.89 2.89 0 0 0-4.134 0l-.622.638-.89-.011a2.89 2.89 0 0 0-2.924 2.924l.01.89-.636.622a2.89 2.89 0 0 0 0 4.134l.637.622-.011.89a2.89 2.89 0 0 0 2.924 2.924l.89-.01.622.636a2.89 2.89 0 0 0 4.134 0l.622-.637.89.011a2.89 2.89 0 0 0 2.924-2.924l-.01-.89.636-.622a2.89 2.89 0 0 0 0-4.134l-.637-.622.011-.89a2.89 2.89 0 0 0-2.924-2.924l-.89.01zM8.5 6v1.5H10a.5.5 0 0 1 0 1H8.5V10a.5.5 0 0 1-1 0V8.5H6a.5.5 0 0 1 0-1h1.5V6a.5.5 0 0 1 1 0' />
              </svg>
            </div>
          </div>
          {inputError && (
            <p className='mt-3 font-bold capitalize text-yellow-400'>
              enter MPepe amount &amp; click buy below
            </p>
          )}

          {chain?.id === 56 ? (
            allowance === "0" ? (
              <button
                className=' mt-8 shadow-2xl rounded-sm gellow text-white font-para text-lg font-semibold outline outline-offset-2 outline-1 outline-[#DDF1B5] hover:scale-[1.03] hover:outline-none duration-300 active:scale-[0.99] px-4 py-2'
                onClick={approve}
                disabled={isPoolLive}>
                {isPoolLive ? "Coming soon" : "Approve $Mpepe"}
              </button>
            ) : (
              <button
                onClick={handleStake}
                disabled={isLoading || isPoolLive}
                className=' mt-8 shadow-2xl rounded-sm gellow text-white font-para text-lg font-semibold outline outline-offset-2 outline-1 outline-[#DDF1B5] hover:scale-[1.03] hover:outline-none duration-300 active:scale-[0.99] px-4 py-2'>
                {isLoading ? "Confirming..." : "Mine Mpepe"}
              </button>
            )
          ) : isConnected ? (
            <button
              className=' mt-8 shadow-2xl rounded-sm gellow text-white font-para text-lg font-semibold outline outline-offset-2 outline-1 outline-[#DDF1B5] hover:scale-[1.03] hover:outline-none duration-300 active:scale-[0.99] px-4 py-2'
              onClick={() => switchChain?.({ chainId: 56 })}>
              {" "}
              Switch Network
            </button>
          ) : (
            <button
              className='flex w-full items-center justify-center gap-3 rounded-md border transition-all duration-200 whitespace-nowrap border-transparent bg-[#DDF1B5]  text-black hover:border-white mt-5 hover:bg-transparent hover:text-white px-4 py-2 text-base'
              onClick={() => open()}>
              Connect Wallet
            </button>
          )}

          <div className='mt-3 flex flex-col gap-3 lg:flex-row'>
            <button
              onClick={handleReStake}
              disabled={isLoading || isPoolLive}
              className='w-full rounded-md border transition-all duration-200 bg-[#DDF1B5] font-para font-semibold text-black hover:bg-transparent px-4 py-2 text-base'
              type='button'>
              Compound Miners
            </button>
            <button
              onClick={handleUnStake}
              disabled={isLoading || isPoolLive}
              className='w-full rounded-md border transition-all duration-200 bg-[#DDF1B5] text-black font-semibold hover:bg-transparent px-4 py-2 text-base'
              type='button'>
              Collect Rewards
            </button>
          </div>
        </div>
        <div className='mt-3 rounded-xl bg-[#1f4921] p-4 flex justify-between'>
          <p className='text-xl '>Daily Profit: UP TO 12%</p>
          <p>|</p>
          <p className=' text-xl '>DEV: 4%</p>
        </div>
      </div>
      <div className='flex flex-col gap-6'>
        <div className='flex items-center gap-3 rounded-md bg-[#1f4921] p-4  '>
          {isConnected ? (
            chain?.id === 56 ? (
              <div
                className='flex justify-between flex-1'
                onClick={() => open()}>
                <p className='font-bold'>Connected account</p>
                <p className='font-bold'>
                  {" "}
                  {truncate(
                    `${ensName ? `${ensName} (${address})` : address}`,
                    8,
                  )}
                </p>
              </div>
            ) : (
              <button
                onClick={() => switchChain({ chainId: 56 })}
                className='flex w-full items-center justify-center gap-3 rounded-md border transition-all duration-200 whitespace-nowrap border-transparent bg-[#DDF1B5]  text-black hover:border-white hover:bg-transparent hover:text-white px-4 py-2 text-base'>
                Switch to Binance Smart Chain
              </button>
            )
          ) : (
            <button
              onClick={() => open()}
              className='flex w-full items-center justify-center gap-3 rounded-md border transition-all duration-200 whitespace-nowrap border-transparent bg-[#DDF1B5]  text-black hover:border-white hover:bg-transparent hover:text-white px-4 py-2 text-base'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width={16}
                height={16}
                fill='currentColor'
                className='bi bi-wallet2'
                viewBox='0 0 16 16'>
                <path d='M12.136.326A1.5 1.5 0 0 1 14 1.78V3h.5A1.5 1.5 0 0 1 16 4.5v9a1.5 1.5 0 0 1-1.5 1.5h-13A1.5 1.5 0 0 1 0 13.5v-9a1.5 1.5 0 0 1 1.432-1.499zM5.562 3H13V1.78a.5.5 0 0 0-.621-.484zM1.5 4a.5.5 0 0 0-.5.5v9a.5.5 0 0 0 .5.5h13a.5.5 0 0 0 .5-.5v-9a.5.5 0 0 0-.5-.5z' />
              </svg>

              <p className='font-bold'>Connect Wallet</p>
            </button>
          )}
        </div>
        <div className='flex items-center gap-4 rounded-md bg-[#1f4921] p-4'>
          <div className='flex flex-1 flex-col  divide-y-2'>
            <div className='flex justify-between w-full'>
              <p className='font-bold'>Contract Balance:</p>
              <p className='font-bold'>
                {contractValue?.status === "success" ? (
                  <CountUp
                    delay={2}
                    start={0}
                    decimals={3}
                    end={fromWei(contractValue?.data[0]?.result)}
                  />
                ) : (
                  "?"
                )}{" "}
                MPepe
              </p>
            </div>
            <div className='flex justify-between w-full'>
              <p className='font-bold'>Wallet Balance:</p>
              <p className='font-bold'>
                {" "}
                <CountUp
                  delay={2}
                  start={0}
                  decimals={3}
                  end={Math.round(balance * 1000) / 1000}
                />
                &nbsp; MPepe
              </p>
            </div>
          </div>
        </div>
        <hr className='mx-auto w-4/5 opacity-60' />
        <div className='space-y-8'>
          <hr className='w-1/5 rounded-md border-4' />
          <div className='flex flex-col gap-4'>
            <div className='flex items-center gap-4'>
              <span className='flex h-14 w-14 flex-shrink-0 items-center justify-center rounded-full border-4 border-[#1f4921] bg-[#DDF1B5] font-head text-black font-bold'>
                1
              </span>
              <p className='font-semibold'>Hold $Mpepe in your wallet.</p>
            </div>
            <div className='flex items-center gap-4'>
              <span className='flex h-14 w-14 flex-shrink-0 items-center justify-center rounded-full border-4 border-[#1f4921] bg-[#DDF1B5] font-head text-black font-bold'>
                2
              </span>
              <p className='font-semibold'>Mine $Mpepe</p>
            </div>
            <div className='flex items-center gap-4'>
              <span className='flex h-14 w-14 flex-shrink-0 items-center justify-center rounded-full border-4 border-[#1f4921] bg-[#DDF1B5] font-head text-black font-bold'>
                3
              </span>
              <p className='font-semibold'>
                You can compound miners using Compound Miners button.
              </p>
            </div>
            <div className='flex items-center gap-4'>
              <span className='flex h-14 w-14 flex-shrink-0 items-center justify-center rounded-full border-4 border-[#1f4921] bg-[#DDF1B5] font-head text-black font-bold'>
                4
              </span>
              <p className='font-semibold'>
                Click Collect Rewards to withdraw collected MPepe.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    // </section>
  );
};

export default MpepePool;
