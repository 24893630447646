/** @format */

import React from "react";

const HowToStart = () => {
  return (
    <section
      className='flex min-h-screen flex-col justify-center gap-10 py-20 mx-auto w-full max-w-6xl px-4 sm:px-6 md:px-8 lg:px-10 xl:px-12'
      id='how-to-start'>
      <div className='flex flex-col gap-3'>
        <h2 className='text-center text-3xl font-bold text-[#DDF1B5] font-head  tracking-wide t lg:text-5xl'>
          How To Start Mining
        </h2>
        <h5 className='text-center font-bold  text-white font-para  text-base'>
          Help and instructions for DeFi ecosystem
        </h5>
      </div>
      <div
        className='grid gap-8 rounded-md bg-[#4caf4f5d] p-8 lg:grid-cols-2 border-dashed border-2 border-[#DDF1B5]'
        data-aos='zoom-in'>
        <div className='flex flex-col gap-6 rounded-md bg-[#4caf4f83] p-4'>
          <h3 className='text-center font-semibold text-white lg:text-left text-2xl font-head'>
            Complete{/* */} <span className='text-[#DDF1B5]'>5</span> {/* */}
            simple steps
          </h3>
          <div className='flex flex-col gap-4 text-white'>
            <div className='flex items-center gap-3 rounded-md bg-[#4caf4f] p-2'>
              <div className='flex h-12 w-12 items-center justify-center rounded-md bg-[#DDF1B5] p-4 text-black font-bold font-head'>
                1
              </div>
              <p className='font-bold font-para lg:text-xl'>
                Select Desired pool{" "}
              </p>
            </div>
            <div className='flex items-center gap-3 rounded-md bg-[#4caf4f] p-2'>
              <div className='flex h-12 w-12 items-center justify-center rounded-md bg-[#DDF1B5] p-4 text-black font-bold font-head'>
                2
              </div>
              <p className='font-bold font-para lg:text-xl'>
                Enter "BNB/CAKE/MPEPE" in the pool.
              </p>
            </div>
            <div className='flex items-center gap-3 rounded-md bg-[#4caf4f] p-2'>
              <div className='flex h-12 w-12 items-center justify-center rounded-md bg-[#DDF1B5] p-4 text-black font-bold font-head'>
                3
              </div>
              <p className='font-bold font-para lg:text-xl'>
                Click on Mine Button
              </p>
            </div>
            <div className='flex items-center gap-3 rounded-md bg-[#4caf4f] p-2'>
              <div className='flex h-12 w-12 items-center justify-center rounded-md bg-[#DDF1B5] p-4 text-black font-bold font-head'>
                4
              </div>
              <p className='font-bold font-para lg:text-xl'>Compound daily</p>
            </div>
            <div className='flex items-center gap-3 rounded-md bg-[#4caf4f] p-2'>
              <div className='flex h-12 w-12 items-center justify-center rounded-md bg-[#DDF1B5] p-4 text-black font-bold font-head'>
                5
              </div>
              <p className='font-bold font-para lg:text-xl'>Withdraw profit!</p>
            </div>
          </div>
        </div>
        <div className='flex flex-col gap-4'>
          <div className='flex items-center gap-3'>
            <h3 className='flex-shrink-0 font-bold font-para text-[#DDF1B5]  text-2xl'>
              Instructions
            </h3>
            <hr className='w-full opacity-20' />
          </div>
          <div className='flex items-center gap-2'>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              fill='none'
              viewBox='0 0 24 24'
              strokeWidth='1.5'
              stroke='currentColor'
              className='h-5 w-5 text-[#DDF1B5]'>
              <path
                strokeLinecap='round'
                strokeLinejoin='round'
                d='M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126ZM12 15.75h.007v.008H12v-.008Z'
              />
            </svg>
            <span className='text-sm font-medium text-white font-para'>
              The deposited BNB/CAKE/MPEPE cannot be withdrawn.
            </span>
          </div>
          <ul className='flex flex-col gap-3'>
            <li className='text-base text-gray-100 font-para'>
              <span className='font-bold text-[#DDF1B5]'>1{/* */}.</span>{" "}
              {/* */}
              Send BNB To Metamask/Trustwallet wallet on Binance smart Chain
              Network (BSC).
            </li>
            <li className='text-base text-gray-100 font-para'>
              <span className='font-bold text-[#DDF1B5]'>2{/* */}.</span>{" "}
              {/* */}In Metamask/Trustwallet Browser Connect to {/* */}{" "}
              <a
                href='https://pancakeswap.finance/swap'
                target='_blank'
                rel='noreferrer'
                className='font-bold text-[#DDF1B5]'>
                PINKSALE
              </a>{" "}
              {/* */} and BUY $MPEPE
            </li>
            <li className='text-base text-gray-100 font-para'>
              <span className='font-bold text-[#DDF1B5]'>3{/* */}.</span>{" "}
              {/* */}At Mpepe site:
              <ul className='ml-5 flex flex-col gap-3'>
                <li className='text-xl'>
                  <span className='font-bold text-[#DDF1B5]'>A{/* */}.</span>{" "}
                  {/* */}Enter the BNB/CAKE/MPEPE amount to Join Miner Pools.
                </li>
                <li className='text-base text-gray-100 font-para'>
                  <span className='font-bold text-[#DDF1B5]'>B{/* */}.</span>{" "}
                  {/* */}Click on Mine Button.
                </li>
                <li className='text-base text-gray-100 font-para'>
                  <span className='font-bold text-[#DDF1B5]'>C{/* */}.</span>{" "}
                  {/* */}Compound Daily to increase your profit.
                </li>
                <li className='text-base text-gray-100 font-para'>
                  <span className='font-bold text-[#DDF1B5]'>D{/* */}.</span>{" "}
                  {/* */}Withdraw Profit using the{" "}
                  <span className='font-bold text-[#DDF1B5]'>
                    Collect Rewards
                  </span>{" "}
                  button.
                </li>
              </ul>
            </li>
          </ul>
          <p className='text-base text-gray-100 font-para'>
            <span className='font-bold text-[#DDF1B5]'>In the game,</span>{" "}
            {/* */}the objective is to hire more miners sooner and more
            frequently than other players. This strategy allows you to earn BNB
            faster. By hiring more miners using your daily BNB earnings, you can
            3x your miner count within 30 days or less.
          </p>
        </div>
      </div>
    </section>
  );
};

export default HowToStart;
