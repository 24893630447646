/** @format */

import React from "react";
import toast from "react-hot-toast";
import { useAccount } from "wagmi";

const Referral = () => {
  const { address, isConnected } = useAccount();

  return (
    <section
      className='flex min-h-screen flex-col justify-center gap-10 py-20 mx-auto w-full max-w-6xl px-4 sm:px-6 md:px-8 lg:px-10 xl:px-12'
      id='referral'>
      <div className='flex flex-col gap-3'>
        <h2 className='text-center text-5xl  text-[#DDF1B5] font-bold font-head tracking-wide '>
          Referral Program
        </h2>
        <h5 className='text-center font-bold  text-white font-para  text-base'>
          You can earn 15% of the BNB/Cake/MPepe used to hire miners by anyone
          who starts mining using your referral link.
        </h5>
      </div>
      <div
        className='rounded-md bg-[#4caf4f5d] p-8 container max-w-7xl mx-auto border-dashed border-2 border-[#DDF1B5]'
        data-aos='zoom-in'>
        <div className='flex flex-col gap-6 rounded-md bg-[#4caf4f83] p-4'>
          <div className='grid place-items-center gap-3 rounded-xl bg-[#4caf4f] p-4 lg:flex lg:items-center'>
            <p className='flex-shrink-0 text-center font-bold  lg:flex-1 lg:text-left lg:text-xl text-white'>
              Your Link:{/* */}{" "}
              <span className='break-all text-[#DDF1B5]'>
                {isConnected
                  ? `https://minerpepe.com/dapp?ref=${address}`
                  : " ?"}{" "}
              </span>
            </p>
            <button
              onClick={() =>
                navigator.clipboard
                  .writeText(
                    isConnected
                      ? `https://minerpepe.com/dapp?ref=${address}`
                      : " ?",
                  )
                  .then(() => {
                    toast.success("Copy to clipboard success");
                  })
              }
              className='flex w-40 transform justify-center hover:scale-110 rounded-md border transition-all duration-200 cursor-copy whitespace-nowrap border-transparent bg-white text-[#000] hover:border-white hover:bg-transparent hover:text-white px-4 py-2 text-base'>
              Copy RefLink
            </button>
          </div>

          <p className='text-center text-xl text-white'>
            <span className='font-bold text-[#DDF1B5]'>
              You Can Earn BNB/Cake/MPepe Tokens
            </span>{" "}
            {/* */}for inviting new users to join Mpepe. Inside the MPEPE Smart
            contract, there's a referral program designed to encourage the
            introduction of new investors to the project.
            {/*  */} when invited users deposit and withdraw their tokens. Promote
            your referral link and{/* */}{" "}
            <span className='font-bold text-[#DDF1B5]'>
              Earn 15% Referral Rewards.
            </span>
          </p>
        </div>
      </div>
    </section>
  );
};

export default Referral;
