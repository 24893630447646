/** @format */

import { useState, useEffect } from "react";

import BigNumber from "bignumber.js";
import BN from "bn.js";
import { useAccount } from "wagmi";
import Web3 from "web3";
import { ERC_ABI } from "../erc20";

const ZERO_ADDRESS = "0x0000000000000000000000000000000000000000";

function web3BNToFloatString(
  bn,
  divideBy,
  decimals,
  roundingMode = BigNumber.ROUND_DOWN,
) {
  const converted = new BigNumber(bn.toString());
  const divided = converted.div(divideBy);
  return divided.toFixed(decimals, roundingMode);
}

export default function useBalance(tokenAddress, decimals) {
  const [balance, setBalance] = useState("0");

  const web3 = new Web3(Web3.givenProvider || "http://localhost:3000/");
  const { address, isConnected } = useAccount();

  const getERC20Contract = async () => {
    return web3
      ? new web3.eth.Contract(ERC_ABI, tokenAddress, {
          from: web3.eth.defaultAccount,
        })
      : null;
  };

  useEffect(() => {
    let isCancelled = false;
    const getBalance = async () => {
      return new Promise(async resolve => {
        if (!web3 || !tokenAddress || !isConnected) {
          resolve(new BN("0"));
          return;
        }

        try {
          let value;
          if (tokenAddress === ZERO_ADDRESS) {
            value = await web3.eth.getBalance(address);
          } else {
            const contract = await getERC20Contract(tokenAddress, web3);
            value = await contract.methods.balanceOf(address).call();
          }
          // console.log("Balance:", value); // Log the retrieved balance
          resolve(new BN(value));
        } catch (error) {
          console.error("Error fetching balance:", error); // Log the error
          resolve(new BN("0"));
        }
      });
    };

    async function run() {
      const bn = await getBalance();
      if (!isCancelled) {
        const pow = new BigNumber("10").pow(new BigNumber(decimals));
        setBalance(web3BNToFloatString(bn, pow, 2, BigNumber.ROUND_DOWN));
      }
    }

    run();

    return () => {
      isCancelled = true;
    };
  }, [tokenAddress, web3, decimals, address]);

  // console.log("log === ?", balance);

  return [balance];
}
