/** @format */

import React from "react";
import Footer from "../components/layout/Footer";
import { Link } from "react-router-dom";
import Hero from "../components/elements/Hero";
import Header from "../components/layout/Header";
import Referral from "../components/elements/Referral";
import HowToStart from "../components/elements/HowToStart";
import BNBPool from "../components/elements/BNBPool";
import Tabs from "../components/Tabs";
import Faqs from "../components/elements/Faq";

const Dapp = () => {
  return (
    <React.Fragment>
      <main className='relative flex flex-grow flex-col bg-gradient-to-r from-green-600 to-green-900'>
        <Header />
        <Hero />
        <Tabs />
        {/* <BNBPool /> */}
        <Referral />
        <HowToStart />
        {/* <Faqs /> */}
        <Footer />
      </main>
    </React.Fragment>
  );
};

export default Dapp;
