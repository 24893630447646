/** @format */

import React from "react";
import Footer from "../components/layout/Footer";
import Header from "../components/layout/Header";
import Claim from "../components/elements/Claim";

const PlayGame = () => {
  return (
    <main className='relative flex flex-grow flex-col bg-gradient-to-r from-green-600 to-green-900'>
      <Header />
      <div className='relative w-full h-full flex flex-col justify-center items-center overflow-hidden mb-5'>
        <div className='pt-10 sm:pt-10 lg:pt-20 w-full flex flex-col lg:flex-row'>
          {/* :HERO MAIN */}
          <div className='w-full lg:w-1/2 flex flex-col justify-center items-center text-gray-600'>
            {" "}
            {/* Container */}
            {/* ::Hero Inner */}
            <div className='p-5 flex flex-col justify-center items-center lg:items-start text-center lg:text-left max-w-lg'>
              {/* Hero Title */}
              <h1 className='py-10 text-3xl sm:text-5xl tracking-wide leading-tight select-none  font-bold uppercase text-center text-[#DDF1B5] font-head lg:text-4xl lg:text-left'>
                P2E (GAME)
              </h1>
              {/* Starting Price */}
              <p className=' tracking-wide text-center text-lg font-medium font-para capitalize text-white lg:text-left '>
                MPEPE goes beyond just miner pools; it's a comprehensive project
                where you can both earn rewards and enjoy entertainment
                simultaneously.
                <br />
                <br />
                MPEPE provides a Play-to-Earn game for those involved in our
                Miner pools. Engage in gameplay and unlock substantial rewards
                along the way.
              </p>
              {/* Buttons */}
              <div className='mt-10 flex flex-col sm:flex-row items-center'>
                <a
                  href='https://play.minerpepe.com/'
                  target='_blank'
                  rel='noreferrer'
                  className='m-1.5 py-2.5 px-5 w-[220px] font-bold h-[45px] shadow-2xl animate-pulse gellow text-white font-para text-lg outline outline-offset-2 outline-1 outline-[#DDF1B5] hover:scale-[1.03] hover:outline-none duration-300 active:scale-[0.99]'>
                  <span className='w-full h-full flex items-center justify-center gap-2 px-2 py-3 '>
                    <svg
                      strokeWidth={2}
                      strokeLinejoin='round'
                      strokeLinecap='round'
                      stroke='currentColor'
                      fill='none'
                      className='w-5 h-5'
                      viewBox='0 0 24 24'
                      xmlns='http://www.w3.org/2000/svg'>
                      <path d='M8 13V9m-2 2h4m5-2v.001M18 12v.001m4-.334v5.243a3.09 3.09 0 0 1-5.854 1.382L16 18a3.618 3.618 0 0 0-3.236-2h-1.528c-1.37 0-2.623.774-3.236 2l-.146.292A3.09 3.09 0 0 1 2 16.91v-5.243A6.667 6.667 0 0 1 8.667 5h6.666A6.667 6.667 0 0 1 22 11.667Z' />
                    </svg>
                    Play Now
                  </span>
                </a>
                <a
                  href='#claim'
                  className='m-1.5 py-2.5 px-2 w-[220px] font-bold h-[45px]   gellow text-white font-para text-lg outline outline-offset-2 outline-1 outline-[#DDF1B5] hover:scale-[1.03] hover:outline-none duration-300 active:scale-[0.99]'>
                  <span className='w-full h-full flex items-center justify-center gap-2 px-2 py-3 '>
                    Claim Game Reward
                  </span>
                </a>
              </div>
            </div>
          </div>
          {/* :HERO ILLUSTRATION */}
          <div className='relative w-full lg:w-1/2 flex flex-col justify-center items-center overflow-hidden p-5'>
            <iframe
              className='h-[400px] w-full rounded-lg border border-[#DDF1B5]'
              src='https://www.youtube.com/embed/EyP0DNsEGdk?si=hMUFN4Fre0TrRDp4?autoplay=1&rel=0&controls=0&showinfo=0'
              title='Mpepe game play'
              allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
              allowFullScreen=''
            />
          </div>
        </div>

        {/* :FEATURES */}
        <section className=' py-20  lg:py-[50px]'>
          <div className='container mx-auto'>
            <div className='flex flex-wrap -mx-4'>
              <div className='w-full px-4'>
                <div className='flex flex-wrap items-center justify-center gap-20'>
                  <img
                    alt='Brand '
                    src='assets/unity.png'
                    className='img-fluid w-52'
                  />
                  <img
                    alt='Brand '
                    src='assets/un.png'
                    className='img-fluid w-52'
                  />
                  <img
                    alt='Brand '
                    src='assets/mt.png'
                    className='img-fluid w-52'
                  />
                  <img
                    alt='Brand '
                    src='assets/bscscan-logo.png'
                    className='img-fluid w-52'
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Claim />
      <Footer />
    </main>
  );
};

export default PlayGame;
