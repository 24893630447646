/** @format */

import React from "react";
import { Link } from "react-router-dom";

const Hero = () => {
  return (
    <React.Fragment>
      <div className='absolute inset-x-0 -mt-16   z-[1] h-[124vh]  w-full bg-gradient-to-l from-transparent via-green-400/5 to-green-900' />
      <video
        autoPlay
        loop
        muted
        playsInline
        className='absolute left-0 w-full  -mt-16  opacity-40 bg-img '
        poster='https://i.imgur.com/x0Os7uK.png'>
        {/* <source src='https://i.imgur.com/ujP7Iw3.mp4' type='video/mp4' /> */}
        <source src='assets/vid.mp4' type='video/mp4' />
      </video>
      {/* <div className='absolute left-0  w-full   h-screen opacity-40 bg-img'></div> */}
      <section
        className='flex min-h-[calc(100vh-100px)] flex-col items-center gap-10 py-20 lg:flex-row mx-auto w-full max-w-6xl px-4 sm:px-6 md:px-8 lg:px-10 xl:px-12'
        id='hero'>
        <div className='z-10 flex max-w-3xl flex-col justify-center gap-8 text-center'>
          <div className='flex flex-wrap gap-6 items-center'>
            <a
              href='https://pancakeswap.finance/swap?outputCurrency=0x6d306C2C9CD931160763D99376a68C14D33DC954'
              target='_blank'
              rel='noopener noreferrer'>
              <img
                src='assets/pancakeswap.svg'
                className='img-fluid w-48'
                alt=''
              />
            </a>
            <a
              href='https://www.dexview.com/bsc/0x6d306C2C9CD931160763D99376a68C14D33DC954'
              target='_blank'
              rel='noopener noreferrer'>
              <img src='assets/dexview.svg' className='img-fluid w-44' alt='' />
            </a>
          </div>

          <h1
            className='select-none text-3xl font-bold uppercase text-center text-[#DDF1B5] font-head lg:text-4xl lg:text-left'
            data-aos='fade-right'>
            Introducing Mpepe:
            <br />
            The Cutting-Edge Miner Pools on Binance Smart Chain.
          </h1>
          <h2 className='text-center text-lg font-medium font-para capitalize text-white lg:text-left'>
            Discover the opportunity to earn daily rewards of up to 12% for life
            time.{" "}
          </h2>
          <div
            className='flex flex-wrap justify-center gap-4 lg:justify-start'
            data-aos='fade-up'>
            <a
              href='https://pancakeswap.finance/swap?outputCurrency=0x6d306C2C9CD931160763D99376a68C14D33DC954'
              target='_blank'
              rel='noopener noreferrer'>
              <button className='w-[280px] font-bold h-[45px] shadow-2xl animate-pulse gellow text-white font-para text-lg outline outline-offset-2 outline-1 outline-[#DDF1B5] hover:scale-[1.03] hover:outline-none duration-300 active:scale-[0.99]'>
                Trade on PCS
              </button>
            </a>
            <a href='dapp#mining'>
              <button className='w-[160px] h-[45px] shadow-2xl gellow text-white font-para text-lg outline outline-offset-2 outline-1 outline-[#DDF1B5] hover:scale-[1.03] hover:outline-none duration-300 active:scale-[0.99]'>
                Start Miner
              </button>
            </a>
            <Link to='/play'>
              <button className='w-[60px] h-[45px] shadow-2xl gellow text-white font-para flex justify-center items-center  outline outline-offset-2 outline-1 outline-[#DDF1B5] hover:scale-[1.03] hover:outline-none duration-300 active:scale-[0.99]'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width={25}
                  height={25}
                  fill='currentColor'
                  className='bi bi-controller'
                  viewBox='0 0 16 16'>
                  <path d='M11.5 6.027a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0m-1.5 1.5a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1m2.5-.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0m-1.5 1.5a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1m-6.5-3h1v1h1v1h-1v1h-1v-1h-1v-1h1z' />
                  <path d='M3.051 3.26a.5.5 0 0 1 .354-.613l1.932-.518a.5.5 0 0 1 .62.39c.655-.079 1.35-.117 2.043-.117.72 0 1.443.041 2.12.126a.5.5 0 0 1 .622-.399l1.932.518a.5.5 0 0 1 .306.729q.211.136.373.297c.408.408.78 1.05 1.095 1.772.32.733.599 1.591.805 2.466s.34 1.78.364 2.606c.024.816-.059 1.602-.328 2.21a1.42 1.42 0 0 1-1.445.83c-.636-.067-1.115-.394-1.513-.773-.245-.232-.496-.526-.739-.808-.126-.148-.25-.292-.368-.423-.728-.804-1.597-1.527-3.224-1.527s-2.496.723-3.224 1.527c-.119.131-.242.275-.368.423-.243.282-.494.575-.739.808-.398.38-.877.706-1.513.773a1.42 1.42 0 0 1-1.445-.83c-.27-.608-.352-1.395-.329-2.21.024-.826.16-1.73.365-2.606.206-.875.486-1.733.805-2.466.315-.722.687-1.364 1.094-1.772a2.3 2.3 0 0 1 .433-.335l-.028-.079zm2.036.412c-.877.185-1.469.443-1.733.708-.276.276-.587.783-.885 1.465a14 14 0 0 0-.748 2.295 12.4 12.4 0 0 0-.339 2.406c-.022.755.062 1.368.243 1.776a.42.42 0 0 0 .426.24c.327-.034.61-.199.929-.502.212-.202.4-.423.615-.674.133-.156.276-.323.44-.504C4.861 9.969 5.978 9.027 8 9.027s3.139.942 3.965 1.855c.164.181.307.348.44.504.214.251.403.472.615.674.318.303.601.468.929.503a.42.42 0 0 0 .426-.241c.18-.408.265-1.02.243-1.776a12.4 12.4 0 0 0-.339-2.406 14 14 0 0 0-.748-2.295c-.298-.682-.61-1.19-.885-1.465-.264-.265-.856-.523-1.733-.708-.85-.179-1.877-.27-2.913-.27s-2.063.091-2.913.27' />
                </svg>
              </button>
            </Link>
          </div>
          <div
            className='flex flex-wrap justify-center gap-4 lg:justify-start'
            data-aos='fade-up'>
            <a
              href='https://bscscan.com/address/0x6d306c2c9cd931160763d99376a68c14d33dc954'
              target='_blank'
              rel='noreferrer'
              className='underline'>
              <button className='w-[160px] h-[45px] shadow-2xl gellow text-white font-para text-lg outline outline-offset-2 outline-1 outline-[#DDF1B5] hover:scale-[1.03] hover:outline-none duration-300 active:scale-[0.99]'>
                Contract
              </button>
            </a>
            <a
              href='https://twitter.com/MinerPepe_Coin'
              target='_blank'
              rel='noreferrer'
              className='underline'>
              <button className='w-[160px] h-[45px] shadow-2xl gellow text-white font-para text-lg outline outline-offset-2 outline-1 outline-[#DDF1B5] hover:scale-[1.03] hover:outline-none duration-300 active:scale-[0.99]'>
                X(Twitter)
              </button>
            </a>
            <a
              href='https://t.me/MinerPepe_Coin'
              target='_blank'
              rel='noreferrer'
              className='underline'>
              <button className='w-[160px] h-[45px] shadow-2xl gellow text-white font-para text-lg outline outline-offset-2 outline-1 outline-[#DDF1B5] hover:scale-[1.03] hover:outline-none duration-300 active:scale-[0.99]'>
                Telegram
              </button>
            </a>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default Hero;
