/** @format */

import React, { useState } from "react";
import BNBPool from "./elements/BNBPool";
import toast from "react-hot-toast";
import CakePool from "./elements/CakePool";
import MpepePool from "./elements/MpepePool";

const Tabs = () => {
  const [selected, setSelected] = useState(1);

  const handleButton = buttonId => {
    setSelected(buttonId);
  };
  return (
    <section
      className='flex  flex-col justify-center gap-10 py-20 mx-auto w-full max-w-7xl px-4 sm:px-6 md:px-8 lg:px-10 xl:px-12 relative rounded-lg overflow-hidden '
      id='mining'>
      <h2 className='text-center text-5xl text-[#DDF1B5] font-bold font-head tracking-wide '>
        Mining
      </h2>
      <div className='font-sans flex flex-col md:flex-row shadow-lg  container  relative z-10 '>
        <ul className='bg-[#265629] rounded-lg overflow-hidden min-w-[200px] '>
          <li
            style={
              selected === 1
                ? { background: "#4CAF50" }
                : { background: "none" }
            }
            onClick={() => handleButton(1)}
            className='text-white font-para min-h-[150px] flex flex-col gap-2 items-center justify-center font-bold w-full text-[15px] px-4 border-l-4 border-[#ffc800] cursor-pointer'>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='4em'
              height='4em'
              viewBox='0 0 32 32'>
              <path
                fill='#ffc800'
                d='M16 32C7.163 32 0 24.837 0 16S7.163 0 16 0s16 7.163 16 16s-7.163 16-16 16m-3.884-17.596L16 10.52l3.886 3.886l2.26-2.26L16 6l-6.144 6.144zM6 16l2.26 2.26L10.52 16l-2.26-2.26zm6.116 1.596l-2.263 2.257l.003.003L16 26l6.146-6.146v-.001l-2.26-2.26L16 21.48zM21.48 16l2.26 2.26L26 16l-2.26-2.26zm-3.188-.002h.001L16 13.706L14.305 15.4l-.195.195l-.401.402l-.004.003l.004.003l2.29 2.291l2.294-2.293l.001-.001l-.002-.001z'></path>
            </svg>
            <span>BNB</span>
          </li>
          <li
            onClick={() => handleButton(2)}
            style={
              selected === 2
                ? { background: "#4CAF50" }
                : { background: "none" }
            }
            className='text-white font-para min-h-[150px] flex gap-2 flex-col items-center justify-center font-bold w-full text-[15px]  px-4 border-l-4 border-[#418B01] cursor-pointer'>
            <img src='assets/logo.png' className='w-[5em] h-[5em]' alt='' />
            MPepe
          </li>
          <li
            onClick={() => handleButton(3)}
            style={
              selected === 3
                ? { background: "#4CAF50" }
                : { background: "none" }
            }
            className='text-white font-para min-h-[150px] flex gap-2 flex-col items-center justify-center font-bold w-full text-[15px] px-4 border-l-4 border-[#3CD4E0] cursor-pointer'>
            <img src='assets/cake.svg' className='w-[5em] h-[5em]' alt='' />
            Cake
          </li>
          <li
            onClick={() =>
              toast.success(
                "Coming Soon, \n Stay tuned for something amazing!",
                {
                  icon: "🚀",
                  style: {
                    border: "1px solid #713200",
                    padding: "16px",
                    color: "#713200",
                  },
                  iconTheme: {
                    primary: "#713200",
                    secondary: "#FFFAEE",
                  },
                },
              )
            }
            className='text-white font-para min-h-[150px] flex gap-2 flex-col items-center justify-center font-bold w-full text-[15px] px-4 border-l-4 border-[#e03c3c] cursor-pointer'>
            <svg
              width='5em'
              height='5em'
              viewBox='0 0 24 24'
              data-name='Line Color'
              xmlns='http://www.w3.org/2000/svg'
              className='icon line-color'>
              <path
                d='M12 13c0-2 3-1 3-4S9 6 9 9m3.05 8h-.1'
                style={{
                  fill: "none",
                  stroke: "#ffc800",
                  strokeLinecap: "round",
                  strokeLinejoin: "round",
                  strokeWidth: 2,
                }}
              />
              <circle
                cx={12}
                cy={12}
                r={9}
                style={{
                  fill: "none",
                  stroke: "#fff",
                  strokeLinecap: "round",
                  strokeLinejoin: "round",
                  strokeWidth: 2,
                }}
              />
            </svg>
          </li>
        </ul>
        <div className='md:px-6 md:py-4'>
          {selected === 1 ? (
            <BNBPool />
          ) : selected === 2 ? (
            <MpepePool />
          ) : selected === 3 ? (
            <CakePool />
          ) : null}
        </div>
      </div>
    </section>
  );
};

export default Tabs;
