/** @format */

import React from "react";
import Footer from "../components/layout/Footer";
import { Link } from "react-router-dom";
import CountDown from "../components/elements/CountDown";

const Home = () => {
  return (
    <React.Fragment>
      <main className='relative flex flex-grow flex-col bg-gradient-to-r from-green-600 to-green-900'>
        <div className='mx-auto w-full max-w-7xl px-4 sm:px-6 md:px-8 lg:px-10 xl:px-12'>
          <section
            className='flex min-h-screen flex-col items-center gap-10 py-10 lg:flex-row mx-auto w-full max-w-7xl px-4 sm:px-6 md:px-8 lg:px-10 xl:px-12'
            id='hero'>
            <div className='flex flex-1 flex-col items-center gap-4'>
              <img
                alt='Mpepe background'
                fetchpriority='high'
                decoding='async'
                data-nimg={1}
                className='ml-6 h-[232px] w-[232px] animate-bounce rounded-full object-cover lg:h-[400px] lg:w-[400px]'
                style={{ color: "transparent" }}
                src='assets/logo.png'
              />
              <div className='flex flex-wrap gap-6 items-center'>
                <a
                  href='https://pancakeswap.finance/swap?outputCurrency=0x6d306C2C9CD931160763D99376a68C14D33DC954'
                  target='_blank'
                  rel='noopener noreferrer'>
                  <img
                    src='assets/pancakeswap.svg'
                    className='img-fluid w-48'
                    alt=''
                  />
                </a>
                <a
                  href='https://www.dexview.com/bsc/0x6d306C2C9CD931160763D99376a68C14D33DC954'
                  target='_blank'
                  rel='noopener noreferrer'>
                  <img
                    src='assets/dexview.svg'
                    className='img-fluid w-44'
                    alt=''
                  />
                </a>
              </div>
              {/* <CountDown /> */}
              <div className='flex gap-4 flex-wrap mt-5'>
                <a
                  href='https://pancakeswap.finance/swap?outputCurrency=0x6d306C2C9CD931160763D99376a68C14D33DC954'
                  target='_blank'
                  rel='noopener noreferrer'>
                  <button className='w-[220px] font-bold h-[45px] shadow-2xl animate-pulse gellow text-white font-para text-lg outline outline-offset-2 outline-1 outline-[#DDF1B5] hover:scale-[1.03] hover:outline-none duration-300 active:scale-[0.99]'>
                    Trade on PCS
                  </button>
                </a>
                <Link to='/dapp' className='w-[170px] h-[50px]'>
                  <button className='w-[160px] h-[45px] shadow-2xl gellow text-white font-para text-lg outline outline-offset-2 outline-1 outline-[#DDF1B5] hover:scale-[1.03] hover:outline-none duration-300 active:scale-[0.99]'>
                    Start Mining
                  </button>
                </Link>
                <Link to='/play'>
                  <button className='w-[60px] h-[45px] shadow-2xl gellow text-white font-para flex justify-center items-center  outline outline-offset-2 outline-1 outline-[#DDF1B5] hover:scale-[1.03] hover:outline-none duration-300 active:scale-[0.99]'>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width={25}
                      height={25}
                      fill='currentColor'
                      className='bi bi-controller'
                      viewBox='0 0 16 16'>
                      <path d='M11.5 6.027a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0m-1.5 1.5a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1m2.5-.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0m-1.5 1.5a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1m-6.5-3h1v1h1v1h-1v1h-1v-1h-1v-1h1z' />
                      <path d='M3.051 3.26a.5.5 0 0 1 .354-.613l1.932-.518a.5.5 0 0 1 .62.39c.655-.079 1.35-.117 2.043-.117.72 0 1.443.041 2.12.126a.5.5 0 0 1 .622-.399l1.932.518a.5.5 0 0 1 .306.729q.211.136.373.297c.408.408.78 1.05 1.095 1.772.32.733.599 1.591.805 2.466s.34 1.78.364 2.606c.024.816-.059 1.602-.328 2.21a1.42 1.42 0 0 1-1.445.83c-.636-.067-1.115-.394-1.513-.773-.245-.232-.496-.526-.739-.808-.126-.148-.25-.292-.368-.423-.728-.804-1.597-1.527-3.224-1.527s-2.496.723-3.224 1.527c-.119.131-.242.275-.368.423-.243.282-.494.575-.739.808-.398.38-.877.706-1.513.773a1.42 1.42 0 0 1-1.445-.83c-.27-.608-.352-1.395-.329-2.21.024-.826.16-1.73.365-2.606.206-.875.486-1.733.805-2.466.315-.722.687-1.364 1.094-1.772a2.3 2.3 0 0 1 .433-.335l-.028-.079zm2.036.412c-.877.185-1.469.443-1.733.708-.276.276-.587.783-.885 1.465a14 14 0 0 0-.748 2.295 12.4 12.4 0 0 0-.339 2.406c-.022.755.062 1.368.243 1.776a.42.42 0 0 0 .426.24c.327-.034.61-.199.929-.502.212-.202.4-.423.615-.674.133-.156.276-.323.44-.504C4.861 9.969 5.978 9.027 8 9.027s3.139.942 3.965 1.855c.164.181.307.348.44.504.214.251.403.472.615.674.318.303.601.468.929.503a.42.42 0 0 0 .426-.241c.18-.408.265-1.02.243-1.776a12.4 12.4 0 0 0-.339-2.406 14 14 0 0 0-.748-2.295c-.298-.682-.61-1.19-.885-1.465-.264-.265-.856-.523-1.733-.708-.85-.179-1.877-.27-2.913-.27s-2.063.091-2.913.27' />
                    </svg>
                  </button>
                </Link>
              </div>
            </div>
            <div className='flex flex-1 flex-col items-center gap-8'>
              <h1
                className='w-full text-center font-extrabold lg:text-left text-6xl text-[#DDF1B5] font-head'
                data-aos='fade-up'>
                $MPEPE
              </h1>
              <h2
                className='w-full text-center font-semibold lg:text-left text-3xl text-white font-para'
                data-aos='fade-up'>
                What is MPepe?
              </h2>
              <p
                className='w-full max-w-lg self-start text-center text-base font-medium lg:text-left text-white font-para'
                data-aos='fade-right'>
                MPEPE stands out as the initial Pepe coin for mining on Binance
                Smart Chain. It comes with its own decentralized app and Mobile
                App, offering miners a 12% daily return in BNB and MPEPE tokens.
                Additionally, Mpepe's native token boosts the Total Value Locked
                (TVL) of miners by 2% with each transaction (whether buying or
                selling), directly benefiting the miner's contract. Only those
                who hold $MPEPE/Cake/BNB tokens can kickstart mining activities
                in the available miner pools and receive daily rewards for
                lifetime.
              </p>
              <div className='flex w-full justify-center gap-4 lg:justify-start'>
                <a
                  href='https://play.minerpepe.com/'
                  data-aos='fade-up'
                  target='_blank'
                  rel='noreferrer'
                  className='flex items-center justify-center rounded-full bg-white p-2.5 underline'>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width={20}
                    height={20}
                    fill='currentColor'
                    className='bi bi-controller'
                    viewBox='0 0 16 16'>
                    <path d='M11.5 6.027a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0m-1.5 1.5a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1m2.5-.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0m-1.5 1.5a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1m-6.5-3h1v1h1v1h-1v1h-1v-1h-1v-1h1z' />
                    <path d='M3.051 3.26a.5.5 0 0 1 .354-.613l1.932-.518a.5.5 0 0 1 .62.39c.655-.079 1.35-.117 2.043-.117.72 0 1.443.041 2.12.126a.5.5 0 0 1 .622-.399l1.932.518a.5.5 0 0 1 .306.729q.211.136.373.297c.408.408.78 1.05 1.095 1.772.32.733.599 1.591.805 2.466s.34 1.78.364 2.606c.024.816-.059 1.602-.328 2.21a1.42 1.42 0 0 1-1.445.83c-.636-.067-1.115-.394-1.513-.773-.245-.232-.496-.526-.739-.808-.126-.148-.25-.292-.368-.423-.728-.804-1.597-1.527-3.224-1.527s-2.496.723-3.224 1.527c-.119.131-.242.275-.368.423-.243.282-.494.575-.739.808-.398.38-.877.706-1.513.773a1.42 1.42 0 0 1-1.445-.83c-.27-.608-.352-1.395-.329-2.21.024-.826.16-1.73.365-2.606.206-.875.486-1.733.805-2.466.315-.722.687-1.364 1.094-1.772a2.3 2.3 0 0 1 .433-.335l-.028-.079zm2.036.412c-.877.185-1.469.443-1.733.708-.276.276-.587.783-.885 1.465a14 14 0 0 0-.748 2.295 12.4 12.4 0 0 0-.339 2.406c-.022.755.062 1.368.243 1.776a.42.42 0 0 0 .426.24c.327-.034.61-.199.929-.502.212-.202.4-.423.615-.674.133-.156.276-.323.44-.504C4.861 9.969 5.978 9.027 8 9.027s3.139.942 3.965 1.855c.164.181.307.348.44.504.214.251.403.472.615.674.318.303.601.468.929.503a.42.42 0 0 0 .426-.241c.18-.408.265-1.02.243-1.776a12.4 12.4 0 0 0-.339-2.406 14 14 0 0 0-.748-2.295c-.298-.682-.61-1.19-.885-1.465-.264-.265-.856-.523-1.733-.708-.85-.179-1.877-.27-2.913-.27s-2.063.091-2.913.27' />
                  </svg>
                </a>
                <a
                  href='https://t.me/MinerPepe_Coin'
                  data-aos='fade-up'
                  target='_blank'
                  rel='noreferrer'
                  className='flex items-center justify-center rounded-full bg-white p-2.5 underline'>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width={20}
                    height={20}
                    fill='currentColor'
                    className='bi bi-telegram'
                    viewBox='0 0 16 16'>
                    <path d='M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M8.287 5.906q-1.168.486-4.666 2.01-.567.225-.595.442c-.03.243.275.339.69.47l.175.055c.408.133.958.288 1.243.294q.39.01.868-.32 3.269-2.206 3.374-2.23c.05-.012.12-.026.166.016s.042.12.037.141c-.03.129-1.227 1.241-1.846 1.817-.193.18-.33.307-.358.336a8 8 0 0 1-.188.186c-.38.366-.664.64.015 1.088.327.216.589.393.85.571.284.194.568.387.936.629q.14.092.27.187c.331.236.63.448.997.414.214-.02.435-.22.547-.82.265-1.417.786-4.486.906-5.751a1.4 1.4 0 0 0-.013-.315.34.34 0 0 0-.114-.217.53.53 0 0 0-.31-.093c-.3.005-.763.166-2.984 1.09' />
                  </svg>
                </a>
                <a
                  href='https://twitter.com/MinerPepe_Coin'
                  data-aos='fade-up'
                  target='_blank'
                  rel='noreferrer'
                  className='flex items-center justify-center rounded-full bg-white p-2.5 underline'>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width={20}
                    height={20}
                    fill='currentColor'
                    className='bi bi-twitter-x'
                    viewBox='0 0 16 16'>
                    <path d='M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07-4.425 5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86 13.028h1.36L4.323 2.145H2.865z' />
                  </svg>
                </a>

                <a
                  href='https://www.instagram.com/minerpepe_coin/'
                  data-aos='fade-up'
                  target='_blank'
                  rel='noreferrer'
                  className='flex items-center justify-center rounded-full bg-white p-2.5 underline'>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width={20}
                    height={20}
                    fill='currentColor'
                    className='bi bi-instagram'
                    viewBox='0 0 16 16'>
                    <path d='M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.9 3.9 0 0 0-1.417.923A3.9 3.9 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.9 3.9 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.9 3.9 0 0 0-.923-1.417A3.9 3.9 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599s.453.546.598.92c.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.5 2.5 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.5 2.5 0 0 1-.92-.598 2.5 2.5 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233s.008-2.388.046-3.231c.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92s.546-.453.92-.598c.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92m-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217m0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334' />
                  </svg>
                </a>
                <a
                  href='https://coinmarketcap.com/community/profile/Minerpepe/'
                  data-aos='fade-up'
                  target='_blank'
                  rel='noreferrer'
                  className='flex items-center justify-center rounded-full bg-white p-2.5 underline'>
                  <svg
                    id='Layer_1'
                    width={20}
                    height={20}
                    data-name='Layer 1'
                    xmlns='http://www.w3.org/2000/svg'
                    viewBox='0 0 500.98 508.49'>
                    <defs>
                      <style
                        dangerouslySetInnerHTML={{
                          __html: ".cls-1{fill:#17181b;fill-rule:evenodd;}",
                        }}
                      />
                    </defs>
                    <title>coinmarketcap</title>
                    <path
                      className='cls-1'
                      d='M435.62,303.86c-8.87,5.6-19.31,6.3-27.25,1.82-10.08-5.69-15.63-19-15.63-37.57V212.64c0-26.79-10.59-45.85-28.3-51-30-8.74-52.58,28-61.07,41.77l-52.93,85.82V184.34c-.6-24.14-8.43-38.58-23.32-42.93-9.84-2.88-24.58-1.72-38.89,20.18L69.64,352a209.19,209.19,0,0,1-24.11-97.76c0-114.71,91.92-208,204.91-208s204.9,93.32,204.9,208c0,.2.05.37.06.55s0,.38,0,.58c1.07,22.21-6.12,39.88-19.75,48.49Zm65.25-49.6h0v-.57l0-.57C500.22,113.41,388.14,0,250.43,0,112.35,0,0,114.05,0,254.25S112.35,508.49,250.44,508.49a247.57,247.57,0,0,0,170.26-67.8A23.35,23.35,0,0,0,421.91,408a22.54,22.54,0,0,0-31.83-1.55l-.34.31a202.51,202.51,0,0,1-139.3,55.48c-60.5,0-114.93-26.78-152.47-69.24L204.91,221.31v79.16c0,38,14.75,50.32,27.11,53.91s31.29,1.14,51.15-31.1L342,227.92c1.89-3.08,3.62-5.73,5.21-8v48.22c0,35.55,14.24,64,39.06,78,22.37,12.62,50.5,11.48,73.42-3C487.46,325.56,502.43,293.23,500.87,254.26Z'
                    />
                  </svg>
                </a>
              </div>
              <div className='flex w-full flex-wrap gap-4 md:flex-nowrap text-white'>
                <a
                  href='https://miner-pepe-official.gitbook.io/mpepe/'
                  target='_blank'
                  rel='noreferrer'
                  className='whitespace-nowrap text-lg font-medium '>
                  Pepepaper
                </a>
                <a
                  href='https://www.cyberscope.io/audits/2-mpepe'
                  target='_blank'
                  rel='noreferrer'
                  className='whitespace-nowrap text-lg font-medium '>
                  Audit
                </a>
                <a
                  href='https://bscscan.com/address/0x6d306c2c9cd931160763d99376a68c14d33dc954'
                  target='_blank'
                  rel='noreferrer'
                  className='whitespace-nowrap text-lg font-medium '>
                  Contract
                </a>
              </div>
            </div>
          </section>
        </div>
        <Footer />
      </main>
    </React.Fragment>
  );
};

export default Home;
