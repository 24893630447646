/** @format */

import React from "react";

const Footer = () => {
  return (
    <footer className='pb-4'>
      <p className='text-center text-white text-lg font-medium'>
        © {/* */}2024{/* */} by Mpepe. All Rights Reserved
      </p>
    </footer>
  );
};  

export default Footer;
