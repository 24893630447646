/** @format */

import { useState, useEffect } from "react";
import BN from "bn.js";
import { useAccount } from "wagmi";
import Web3 from "web3";
import { ERC_ABI } from "../erc20";

export default function useAllowance(tokenAddress, spender) {
  const web3 = new Web3(Web3.givenProvider || "http://localhost:3000/");
  const { address, isConnected } = useAccount();
  const [allowance, setAllowance] = useState("0");
  // console.log(account, library)

  const getERC20Contract = async () => {
    return web3
      ? new web3.eth.Contract(ERC_ABI, tokenAddress, {
          from: web3.eth.defaultAccount,
        })
      : null;
  };

  useEffect(() => {
    let isCancelled = false;

    async function getAllowance() {
      return new Promise(async resolve => {
        if (!web3 || !tokenAddress || !isConnected) {
          resolve(new BN("0"));
          return;
        }

        try {
          const contract = await getERC20Contract(tokenAddress, web3);
          const value = await contract.methods
            .allowance(address, spender)
            .call();
          console.log("Allowance:", value); // Log the retrieved allowance
          resolve(new BN(value));
        } catch (error) {
          console.error("Error fetching allowance:", error); // Log the error
          resolve(new BN("0"));
        }
      });
    }

    async function run() {
      const bn = await getAllowance();
      if (!isCancelled) {
        setAllowance(bn.toString());
      }
    }

    run();

    return () => {
      isCancelled = true;
    };
  }, [tokenAddress, web3, spender, address]);

  // console.log("log === ?", balance);

  return allowance;
}
